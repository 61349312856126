import React from 'react';
import './privacy-policy.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatedBlock } from '../components/language';
import Layout from 'src/components/layout';

export default class PrivacyPolicy extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setPage('privacy-policy');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'Privacy policy',
          french: 'Politique de confidentialité des renseignements personnels',
        }}
        showTab={true}
      >
        <TranslatedBlock language="english">
          <h2>Privacy Statement</h2>
          <h3>HOFFMANN-LA ROCHE LIMITED (Roche Canada) Online Privacy Statement</h3>
          <p>
            Thank you for visiting a&nbsp;Roche Web site or interacting with us via e-mail and other social media sites.
            <br />
          </p>
          <p>
            At Roche, we understand that protecting the privacy of visitors to our Web sites is very important and that
            information about your health is particularly sensitive. That is why we have taken the necessary steps to
            meet worldwide data privacy requirements. We treat your “personal data” according to the ”Roche Directive on
            the Protection of Personal Data”, and to the laws of Canada, the EU, Switzerland and other applicable local
            laws which regulate the storage, processing, access and transfer of personal data.
          </p>
          <p>
            Roche Web sites that display this privacy statement (“Statement”) and ask for any information from you are
            committed to collecting, maintaining, and securing personal information about you in accordance with this
            Statement, as well as applicable laws, rules and regulations. This Statement applies to personal information
            (as defined below) collected from Roche online resources and communications (such as Web sites, e-mail, and
            other online tools) that display a&nbsp;link to this Statement. This Statement does not apply to personal
            information collected from offline resources and communications, except in cases where such personal
            information is consolidated with personal information collected by Roche online. This Statement also does
            not apply to third-party online resources to which Roche’s Web sites may link, where Roche does not control
            the content or the privacy practices of such resources.
          </p>
          <p>
            We only collect personally identifiable information about you if you choose to give it to us. We do not
            share any of your personally identifiable information with third parties for their own marketing use unless
            you explicitly give us permission to do so. Please review this Privacy Statement to learn more about how we
            collect, use, share and protect information online.
          </p>
          <p>
            Please be aware that we may use service providers and data processors working on our behalf. The services
            can include hosting and maintenance services, analysis services, e-mail messaging services, delivery
            services, handling of payment transactions, solvency check and address check, etc. These third parties are
            granted access to such personal information they require in order to be able to carry out the particular
            service. The service providers and data processors are contractually obliged to treat such information in
            the strictest confidence. It is also contractually forbidden for them to use the information in any other
            way than required. The necessary steps are taken to ensure that our service providers and the processors
            working on our behalf protect the confidentiality of your personal information. Where personal information
            is stored or processed outside of Canada (whether by us or a&nbsp;service provider or data processor as
            outlined above), it is subject to the laws of that foreign jurisdiction, and maybe accessible to that
            jurisdiction’s government, courts, or law enforcement or regulatory agencies.
          </p>
          <h3>Information Collected</h3>
          <p>There are two general methods that Roche uses to collect information from you online:</p>
          <h3>Information We Get</h3>
          <ul className="inside">
            <li>
              <i>Personal identifiable information</i>: You can visit our Web sites without providing any personal
              information. We may collect your personally identifiable information (such as name, address, telephone
              number, e-mail address or other identifying information) only when you choose to submit it to us. We may
              also collect health information about you that you provide by responding to our questions or surveys.
              &nbsp;&nbsp;
            </li>
            <li>
              <i>Aggregate information</i>: In some cases we also remove personal identifiers from data you provide to
              us and maintain it in aggregate form. We may combine this data with other information to produce
              anonymous, aggregated statistical information (e.g. number of visitors, originating domain name of the
              Internet Service Provider), helpful to us in improving our products and services.
            </li>
          </ul>
          <h3>Automatically Collected Information</h3>
          <p>
            We and some third party providers we work with automatically receive certain types of information whenever
            you interact with us on our sites and in some e-mails we may send each other. Automatic technologies and
            services we use may include, for example, Web server logs/IP addresses, cookies, Web beacons and third party
            application and content tools.
          </p>
          <p>
            <i>Web Server Logs/IP Addresses:</i>&nbsp;An IP address is a&nbsp;number assigned to your computer whenever
            you access the Internet. All computer identification on the Internet is conducted with IP addresses, which
            allow computers and servers to recognize and communicate with each other. Roche collects IP addresses to
            conduct system administration and report aggregate information to affiliates, business partners and/or
            vendors to conduct site analysis and Web site performance review.
          </p>
          <p>
            <i>Cookies:</i>&nbsp;A&nbsp;cookie is a&nbsp;piece of information that is placed automatically on your
            computer’s hard drive when you access certain Web sites. The cookie uniquely identifies your browser to the
            server. Cookies allow us to store information on the server to help make the Web experience better for you
            and to conduct site analysis and Web site performance review. Most Web browsers are set up to accept
            cookies, although you can reset your browser to refuse all cookies or to indicate when a&nbsp;cookie is
            being sent. Note, however, that some portions of our sites may not work properly if you refuse cookies.
          </p>
          <p>
            <i>Web Beacons:&nbsp;</i>On certain Web pages or e-mails, Roche may utilize a&nbsp;common Internet
            technology called a&nbsp;"Web beacon" (also known as an "action tag" or "clear GIF technology"). Web beacons
            help analyze the effectiveness of Web sites by measuring, for example, the number of visitors to a&nbsp;site
            or how many visitors clicked on key elements of a&nbsp;site.
          </p>
          <p>
            Web beacons, cookies and other tracking technologies do not automatically obtain personally identifiable
            information about you. Only if you voluntarily submit personally identifiable information, such as by
            registering or sending e-mails, can these automatic tracking technologies be used to provide further
            information about your use of the Web sites and/or interactive e-mails to improve their usefulness to you.
          </p>
          <p>
            <i>Services:</i> We may provide services based on third party applications and content tools on certain
            Roche Websites such as Google Maps or QUARTAL FLIFE. These third parties may automatically receive certain
            types of information whenever you interact with us on our sites using such third party applications and
            tools.
          </p>
          <h3>Your Choices</h3>
          <p>
            You have several choices regarding your use of our Web sites. You could decide not to submit any personally
            identifiable information at all by not entering it into any forms or data fields on our sites and not using
            any available personalized services. If you choose to submit personal data, you have the right to see and
            correct your data at any time by accessing the application. Certain sites may ask for your permission for
            certain uses of your information and you can agree to or decline those uses. If you opt-in for particular
            services or communications, such as an e-newsletter, you will be able to unsubscribe at any time by
            following the instructions included in each communication. If you decide to unsubscribe from a&nbsp;service
            or communication, we will work to remove your information promptly, although we may require additional
            information before we can process your request.
          </p>
          <p>
            As described above, if you wish to prevent cookies from tracking you anonymously as you navigate our sites,
            you can reset your browser to refuse all cookies or to indicate when a&nbsp;cookie is being sent.
          </p>
          <h3>Security</h3>
          <p>
            Roche uses technology and security precautions, rules and other procedures to protect your personal
            information from unauthorized access, improper use, disclosure, loss or destruction. To ensure the
            confidentiality of your information, Roche uses also industry standard firewalls and password protection. It
            is, however, your personal responsibility to ensure that the computer you are using is adequately secured
            and protected against malicious software, such as trojans, computer viruses and worm programs. You are aware
            of the fact that without adequate security measures (e.g. secure web browser configuration, up-to-date
            antivirus software, personal firewall software, no usage of software from dubious sources) there is
            a&nbsp;risk that the data and passwords you use to protect access to your data, could be disclosed to
            unauthorized third parties.
          </p>
          <h3>Use of Data</h3>
          <p>
            Roche, including the subsidiaries, divisions and groups worldwide and/or the companies we hire to perform
            services on our behalf will use any personally identifiable information you choose to give us to comply with
            your requests. We will retain control of and responsibility for the use of this information. Some of this
            data may be stored or processed at computers located in other jurisdictions, such as the United States,
            whose data protection laws may differ from the jurisdiction in which you live. In such cases, we will ensure
            that appropriate protections are in place to require the data processor in that country to maintain
            protections on the data that are equivalent to those that apply in the country in which you live.
          </p>
          <p>
            The information, which is also used for different HR purposes (Performance Management, Succession decisions
            or Development actions), will be helpful for us to better understand your needs and how we can improve our
            products and services. It helps us also to personalize certain communications with you about services and
            promotions that you might find interesting. For example, we may analyze the gender or age of visitors to
            sites about a&nbsp;particular medication or disease state, and we may use that analysis of aggregate data
            internally or share it with others.
          </p>
          <h3>Data Sharing and Transfer</h3>
          <p>
            Roche shares personally identifiable data about you with various outside companies or agents doing
            technological maintenance or working on our behalf to help fulfill business transactions, such as providing
            customer services, sending marketing communications about our products, services and offers. We may also
            share personally identifiable data with our company's subsidiaries and affiliates. All these companies and
            agents are required to comply with the terms of our privacy policies.
          </p>
          <p>We may also disclose personally identifiable information for these purposes:</p>
          <ol className="inside">
            <li>
              In connection with the sale, assignment or other transfer of the business of the site to which the data
              relates;
            </li>
            <li>
              To respond to appropriate requests of legitimate government agencies or where required by applicable laws,
              court orders, or government regulations; or
            </li>
            <li>
              Where needed for corporate audits or to investigate or respond to a&nbsp;complaint or security threat.
            </li>
          </ol>
          <p>
            <i>No Third-Party Direct Marketing Use</i>: We will not sell or otherwise transfer the personally
            identifiable information you provide to us at our Web sites to any third parties for their own direct
            marketing use unless we provide clear notice to you and obtain your explicit consent for your data to be
            shared in this manner.
          </p>
          <p>
            <i>E-mail a&nbsp;Friend or Colleague:</i>&nbsp;On some Roche sites, you can choose to send a&nbsp;link or
            a&nbsp;message to a&nbsp;friend or colleague referring them to a&nbsp;Roche Web site. E-mail addresses you
            may provide for a&nbsp;friend will be used to send your friend information on your behalf and will not be
            collected or used by Roche or other third parties for additional purposes.
          </p>
          <p>
            <i>Google Analytics:&nbsp;</i>Roche Websites may use{' '}
            <a href="https://www.google.com/analytics/tos.html" target="_blank" rel="noopener noreferrer">
              Google Analytics
            </a>
            , a&nbsp;web analytics service provided by Google, Inc. ("Google"). Google Analytics uses "cookies", which
            are text files placed on your computer, to help the website analyze how users use the site. The information
            generated by the cookie about your use of the website (including your IP address) will be transmitted to and
            stored by Google on servers in the United States . Google will use this information for the purpose of
            evaluating your use of the website, compiling reports on website activity for website operators and
            providing other services relating to website activity and internet usage. Google may also transfer this
            information to third parties where required to do so by law, or where such third parties process the
            information on Google's behalf. Google will not associate your IP address with any other data held by
            Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however, as
            mentioned above please note that if you do this you may not be able to use the full functionality of
            a&nbsp;Roche website. By using a&nbsp;Roche website, you consent to the processing of data about you by
            Google in the manner and for the purposes set out above.
          </p>
          <h3>Links to Other Sites</h3>
          <p>
            Our sites contain links to a&nbsp;number of Web sites that may offer useful information to our visitors.
            This Privacy Statement does not apply to those sites, and we recommend communicating to them directly for
            information on their privacy policies.
          </p>
          <h3>Privacy Statement for Children</h3>
          <p>
            Our Web sites are directed at an adult audience. We do not collect any personally identifiable information
            from anyone we know to be under the age of 13 without the prior, verifiable consent of his or her legal
            representative. Such legal representative has the right, upon request, to view the information provided by
            the child and/or to require that it be deleted.
          </p>
          <h3>Note to Users of Business or Professional Web Sites</h3>
          <p>
            If you have a&nbsp;business or professional relationship with Roche, we may use information you submit on
            our sites, including sites intended specifically for business and professional users, to fulfill your
            requests and develop our business relationship with you and the entities you represent. We may also share
            such information with third parties acting on our behalf.
          </p>
          <h3>Updates to Privacy Statement</h3>
          <p>
            From time to time, Roche may revise this online Privacy Statement. Any such changes to this Privacy
            Statement will be promptly communicated on this page. Continued use of our sites after receiving notice of
            a&nbsp;change in our Privacy Statement indicates your consent to the use of newly submitted information in
            accordance with the amended Roche Privacy Statement. The effective date of this Privacy Statement is{' '}
            <b>July 1, 2013.</b>
          </p>
          <h3>How to Contact Roche</h3>
          <p>
            For questions or if you wish Roche to amend or delete your profile, please contact us in writing (by post),
            as follows:
          </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    Hoffmann-La Roche Limited (Pharma)
                    <br />
                    Privacy Officer
                    <br />
                    7070 Mississauga Road
                    <br />
                    Mississauga, Ontario
                    <br />
                    L5N 5M8
                  </p>
                </td>
                <td>
                  <p>
                    Roche Diagnostics
                    <br />
                    Division of Hoffmann-La Roche Limited
                    <br />
                    Privacy Officer
                    <br />
                    201 Armand-Frappier Blvd
                    <br />
                    Laval, Quebec H7V 4A2
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </TranslatedBlock>
        <TranslatedBlock language="french">
          <h2>Politique de confidentialité</h2>
          <h3>Énoncé de confidentialité en ligne de HOFFMANN-LA ROCHE LIMITED/LIMITÉE (Roche Canada)</h3>
          <p>
            Merci de visiter un site Web de Roche ou d’interagir avec nous par courriel et sur des sites de médias
            sociaux.
          </p>
          <p>
            Chez Roche, nous sommes conscients de l’importance de protéger la vie privée des personnes qui visitent nos
            sites Web et de la nature particulièrement délicate des renseignements qui concernent leur santé. C’est
            pourquoi nous avons pris les mesures nécessaires pour respecter les exigences de confidentialité des données
            à&nbsp;l’échelle mondiale. Nous traitons vos «&nbsp;données personnelles&nbsp;» conformément à&nbsp;la
            «&nbsp;directive concernant la protection des données personnelles de Roche&nbsp;», aux lois du Canada, de
            l’Union européenne et de la Suisse, et à&nbsp;d’autres lois applicables à&nbsp;l’échelle locale qui
            régissent le stockage, le traitement, la consultation et le transfert des données personnelles.
          </p>
          <p>
            Sur les sites Web de Roche qui affichent le présent énoncé de confidentialité («&nbsp;l’énoncé&nbsp;») et
            vous demandent de fournir tout renseignement, Roche est soucieuse de recueillir, de conserver et de protéger
            les renseignements personnels vous concernant conformément au présent énoncé, de même qu’aux lois,
            règlements et règles applicables. Cet énoncé s’applique aux renseignements personnels (tels qu’ils sont
            définis ci-dessous) recueillis par Roche au moyen de ressources et de communications en ligne (sites Web,
            courriel et autres outils accessibles en ligne) qui contiennent un lien vers le présent énoncé. Cet énoncé
            ne s’applique pas aux renseignements personnels recueillis au moyen de ressources et de communications hors
            ligne, sauf lorsque ces renseignements sont regroupés avec des renseignements personnels recueillis en ligne
            par Roche. Il ne s’applique pas non plus aux ressources en ligne exploitées par des tiers auxquelles les
            sites Web de Roche peuvent renvoyer, lorsque Roche n’exerce aucun contrôle sur le contenu ni sur les
            pratiques de confidentialité de ces ressources.
          </p>
          <p>
            Nous ne recueillons sur vous des renseignements personnels permettant de remonter jusqu'à&nbsp;vous que si
            vous nous les donnez de plein gré. Nous ne partageons aucun de vos renseignements personnels avec des tiers
            pour leur propre utilisation à&nbsp;des fins de commercialisation, à&nbsp;moins que vous nous donniez
            l’autorisation de le faire. Veuillez lire attentivement le présent énoncé de confidentialité pour connaître
            la façon dont nous recueillons, utilisons, partageons et protégeons les renseignements en ligne.
          </p>
          <p>
            Soyez avisé que nous pourrions faire appel à&nbsp;des fournisseurs de services et à&nbsp;des préposés au
            traitement des données qui travaillent pour notre compte. Les services peuvent comprendre des services
            d’hébergement et de maintenance, d’analyse, de courriel, de livraison, de traitement des transactions de
            paiement, de vérification de la solvabilité, de vérification d’adresse, etc. Ces tiers obtiennent un accès
            aux renseignements personnels dont ils ont besoin pour exécuter le service en question. Les fournisseurs de
            services et les préposés au traitement des données ont l’obligation contractuelle de traiter ces
            renseignements à&nbsp;titre strictement confidentiel. En vertu de leur contrat, il leur est également
            interdit d’utiliser les renseignements autrement qu’aux fins pour lesquelles ils ont été mis à&nbsp;leur
            disposition. Nous prenons les mesures nécessaires pour faire en sorte que les fournisseurs de services et
            les préposés au traitement des données qui travaillent pour notre compte protègent la confidentialité de vos
            renseignements personnels. Si les renseignements personnels sont stockés ou traités à&nbsp;l’extérieur du
            Canada (par nous ou par un fournisseur de services ou un préposé au traitement des données, tel qu’il est
            décrit ci-dessus), ils sont assujettis aux lois de ce pays étranger et pourraient être accessibles au
            gouvernement, aux tribunaux ou aux organismes d’application de la loi ou de réglementation de ce pays.
          </p>
          <h3>Renseignements recueillis</h3>
          <p>Roche utilise deux méthodes générales pour recueillir des renseignements personnels en ligne :</p>
          <h3>Les renseignements que nous recueillons</h3>
          <ul className="inside">
            <li>
              <i>Renseignements personnels&nbsp;:</i> Vous pouvez visiter nos sites Web sans nous fournir de
              renseignements personnels. Nous ne pouvons recueillir des renseignements personnels (nom, adresse, numéro
              de téléphone, adresse de courriel ou tout autre renseignement permettant de vous identifier
              personnellement) que si vous nous les donnez de plein gré. Nous pouvons également recueillir des
              renseignements sur votre santé lorsque vous répondez à&nbsp;nos questionnaires et à&nbsp;nos sondages.
            </li>
            <li>
              <i>Renseignements regroupés&nbsp;:</i> Dans certains cas, nous pouvons également supprimer les
              identificateurs personnels des données que vous nous avez fournies et les conserver sous forme regroupée.
              Nous pouvons regrouper ces données avec d’autres renseignements pour produire des statistiques globales et
              anonymes (p.&nbsp;ex. le nombre de visiteurs ou le nom de domaine originaire du fournisseur d’accès
              Internet), qui peuvent nous être utiles pour améliorer nos produits et services.
            </li>
          </ul>
          <h3>Les renseignements recueillis automatiquement</h3>
          <p>
            Nous et certains fournisseurs tiers avec qui nous travaillons recevons automatiquement certains types de
            renseignements chaque fois que vous interagissez avec nos sites Web et dans certains courriels que nous nous
            envoyons mutuellement. Les technologies et services automatiques que nous utilisons peuvent comprendre entre
            autres les journaux de serveurs Web/adresses IP, les témoins et les pixels invisibles, ainsi que des
            applications et des outils de gestion du contenu de tiers.
          </p>
          <p>
            <i>Journaux de serveurs Web/adresses IP.</i>&nbsp;Une adresse IP est un numéro attribué à&nbsp;votre
            ordinateur chaque fois que vous accédez à&nbsp;Internet. Toute identification d’ordinateur sur Internet
            s’effectue par l’attribution d’une adresse IP, qui permet aux ordinateurs et aux serveurs de se reconnaître
            et de communiquer entre eux. Roche recueille les adresses IP pour assurer l’administration de son système et
            pour transmettre des renseignements regroupés à&nbsp;ses sociétés affiliées, ses partenaires commerciaux ou
            ses fournisseurs afin de mener des analyses de site et une évaluation du rendement des sites Web.
          </p>
          <p>
            <i>Témoins.</i> Un témoin est un élément d’information qui est placé automatiquement sur le disque dur de
            votre ordinateur lorsque vous accédez à&nbsp;certains sites Web. Le témoin permet au serveur d’identifier
            votre navigateur. Les témoins nous permettent de stocker de l’information sur le serveur pour rendre votre
            expérience sur le Web plus conviviale et pour mener des analyses de site et une évaluation du rendement des
            sites Web. La plupart des navigateurs sont configurés pour accepter les témoins, mais vous pouvez modifier
            la configuration du vôtre afin qu’il refuse tous les témoins ou vous avise lorsqu’un témoin vous est
            transmis. Veuillez prendre note toutefois que certaines sections de notre site Web&nbsp;peuvent ne pas bien
            fonctionner si vous décidez de ne pas accepter les témoins.
          </p>
          <p>
            <i>Pixels invisibles</i>. Sur certaines pages Web ou dans certains courriels, Roche peut utiliser une
            technologie Internet courante appelée «&nbsp;pixel invisible&nbsp;» (également connue sous le nom de
            «&nbsp;pixel espion&nbsp;» ou «&nbsp;GIF invisible&nbsp;»). Les pixels invisibles permettent d’analyser
            l’efficacité de sites Web en mesurant, par exemple, le nombre de visiteurs d’un site ou le nombre de
            visiteurs qui ont cliqué sur des éléments clés d’un site.
          </p>
          <p>
            Les pixels invisibles, les témoins et toute autre technologie de surveillance ne permettent pas d’obtenir
            automatiquement des renseignements personnels à&nbsp;votre sujet. Ce n’est que lorsque vous nous soumettez
            de plein gré des renseignements personnels, comme en enregistrant votre adresse électronique auprès de nous
            ou en nous faisant parvenir un courriel, que ces technologies de surveillance peuvent être utilisées pour
            fournir de plus amples renseignements sur votre utilisation des sites Web ou des courriels interactifs afin
            d’améliorer leur utilité pour vous.
          </p>
          <p>
            <i>Services&nbsp;:</i> Sur certains des sites Web de Roche, nous pouvons offrir des services faisant appel
            à&nbsp;des applications et des outils de gestion du contenu de tiers, tels que Google Maps ou QUARTAL FLIFE.
            Ces tiers peuvent recevoir automatiquement certains types de renseignements chaque fois que vous
            interagissez avec nous sur nos sites en vous servant de telles applications et de tels outils de tiers.
          </p>
          <h3>Vos choix</h3>
          <p>
            Plusieurs choix s’offrent à&nbsp;vous quant à&nbsp;votre utilisation de nos sites Web. Vous pouvez décider
            de ne soumettre aucun renseignement personnel en n’entrant aucun renseignement de ce type dans les
            formulaires ou champs de données et en n’utilisant aucun des services personnalisés offerts. Si vous
            choisissez de soumettre des données personnelles, vous avez le droit de les consulter et de les corriger en
            tout temps en accédant à&nbsp;l’application. Certains sites peuvent vous demander l’autorisation d’utiliser
            vos renseignements à&nbsp;certains usages; vous avez la possibilité d’accepter ou de refuser. Si vous
            choisissez de vous abonner à&nbsp;certains services ou à&nbsp;certaines communications, comme un bulletin
            électronique, vous serez en mesure de vous désabonner de ce service ou de cette communication en tout temps
            en suivant les directives fournies à&nbsp;cet effet dans chaque communication. Si vous décidez de vous
            désabonner d’un service ou d’une communication, nous nous efforcerons de supprimer vos renseignements dans
            les plus brefs délais, bien qu’il soit possible que nous vous demandions d’autres renseignements avant de
            pouvoir traiter votre demande.
          </p>
          <p>
            Comme nous l’avons mentionné ci-dessus, si vous souhaitez empêcher que des témoins vous retracent de façon
            anonyme chaque fois que vous naviguez sur nos sites, vous pouvez modifier la configuration de votre
            navigateur afin qu’il refuse tous les témoins ou vous avise lorsqu’un témoin vous est transmis.
          </p>
          <h3>Sécurité</h3>
          <p>
            Roche a adopté des mesures de sécurité, des règles et d’autres procédures technologiques pour protéger vos
            données personnelles de l’accès non autorisé, de la mauvaise utilisation, de la divulgation, de la perte ou
            de la destruction. Afin d’assurer la confidentialité de vos données, Roche utilise des pare-feux et des
            protections par mots de passe qui répondent aux normes de l’industrie. Il vous incombe cependant de vous
            assurer que l’ordinateur que vous utilisez est adéquatement sécurisé et qu’il est protégé contre les
            logiciels malveillants comme les chevaux de Troie, les virus et les vers informatiques. Vous devez être
            conscient que sans l’adoption de mesures de sécurité adéquates (p.&nbsp;ex. la configuration sécurisée du
            navigateur, un logiciel antivirus à&nbsp;jour, l’installation d’un pare-feu personnel et la non-utilisation
            de logiciels de sources douteuses), les données et les mots de passe que vous utilisez pour protéger l’accès
            à&nbsp;vos données risquent d’être divulgués à&nbsp;des tiers non autorisés.
          </p>
          <h3>Utilisation des données</h3>
          <p>
            Roche, y compris les filiales, divisions et groupes à&nbsp;l’échelle mondiale ou les sociétés que nous
            embauchons pour assurer la prestation de services pour notre compte, utiliseront tout renseignement
            personnel que vous choisissez de nous donner pour que nous répondions à&nbsp;vos demandes. Nous conserverons
            le contrôle et la responsabilité de l’utilisation de ces renseignements. Certaines de ces
            données&nbsp;peuvent être stockées ou traitées sur des ordinateurs situés dans d’autres pays, notamment les
            États-Unis, qui&nbsp;peuvent appliquer des lois sur la protection des données qui diffèrent de celles de
            votre pays de résidence. Dans ces cas, nous assurerons la mise en place des mesures de protection
            appropriées pour obliger le préposé au traitement des données de ce pays à&nbsp;maintenir des mesures de
            protection équivalentes à&nbsp;celles qui s’appliquent dans votre pays de résidence.
          </p>
          <p>
            Les renseignements, qui sont également utilisés à&nbsp;différentes fins de RH (gestion du rendement,
            planification de la relève ou mesures de perfectionnement), nous serviront à&nbsp;mieux comprendre vos
            besoins et les façons dont nous pouvons améliorer nos produits et services. Ils nous aident aussi
            à&nbsp;personnaliser certaines communications que nous vous envoyons à&nbsp;propos des services et des
            promotions qui pourraient vous intéresser. Par exemple, nous pouvons analyser le sexe ou l’âge des personnes
            qui consultent nos sites au sujet d’un médicament ou d’un problème de santé donné, et nous pouvons analyser
            les données regroupées à&nbsp;l’interne ou les partager avec des tiers.
          </p>
          <h3>Partage et transfert des données</h3>
          <p>
            Roche partage des données personnelles avec différentes sociétés tierces ou différents mandataires qui
            assurent la maintenance technologique ou travaillent pour notre compte afin de permettre la réalisation
            d’opérations commerciales, comme la prestation de services à&nbsp;la clientèle et la transmission de
            communications de mise en marché sur nos produits, nos services et nos offres. Nous pouvons également
            partager des données personnelles avec nos filiales et nos sociétés affiliées. Toutes ces sociétés et tous
            ces mandataires sont tenus de se conformer aux modalités prévues dans nos politiques en matière de
            confidentialité.
            <br />
            <br />
            Nous pouvons également divulguer des renseignements personnels aux fins suivantes :
          </p>
          <ol className="inside">
            <li>
              pour la vente, la cession ou tout autre transfert de l’exploitation commerciale du site auquel les données
              sont reliées;
            </li>
            <li>
              pour répondre aux demandes acceptables d'organismes gouvernementaux légitimes ou lorsque les lois, les
              ordonnances de tribunaux ou les règlements gouvernementaux applicables l’exigent; ou
            </li>
            <li>
              lorsque des vérifications effectuées par le Groupe l’exigent ou pour répondre à&nbsp;une plainte ou une
              menace à&nbsp;la sécurité.
            </li>
          </ol>
          <p>
            <i>Aucune utilisation en vue de la vente directe par des tiers</i>. Nous nous engageons à&nbsp;ne pas vendre
            ou céder à&nbsp;des tiers les renseignements personnels que vous nous fournissez sur nos sites Web en vue de
            leur utilisation à&nbsp;des fins de vente directe, à&nbsp;moins que nous vous en avisions de façon claire et
            que vous nous donniez votre consentement explicite à&nbsp;un tel partage de vos données.
          </p>
          <p>
            <i>Envoi d’un courriel à&nbsp;un ami ou à&nbsp;un collègue</i>. Sur certains sites de Roche, vous pouvez
            choisir de transmettre un lien ou un message à&nbsp;un ami ou un collègue les renvoyant à&nbsp;un site Web
            de Roche. L’adresse de courriel que vous fournissez au nom d’un ami sera utilisée pour lui transmettre des
            renseignements en votre nom et ne sera pas recueillie ni utilisée par Roche ou tout autre tiers
            à&nbsp;d’autres fins.
          </p>
          <p>
            <i>Google Analytics</i>. Les sites Web de Roche peuvent utiliser{' '}
            <a href="https://www.google.com/analytics/tos.html" target="_blank" rel="noopener noreferrer">
              Google Analytics
            </a>
            , un service d’analyse d’auditoire Internet fourni par Google, Inc. («&nbsp;Google&nbsp;»). Google Analytics
            fait appel à&nbsp;des «&nbsp;témoins&nbsp;», c’est-à-dire des fichiers texte qui sont placés sur votre
            ordinateur, pour aider les administrateurs de sites Web à&nbsp;analyser comment les visiteurs les utilisent.
            Les renseignements générés par le témoin au sujet de l’utilisation que vous faites du site Web (y compris
            votre adresse IP) seront transmis à&nbsp;Google qui les conservera sur des serveurs aux États-Unis. Google
            utilisera ces renseignements pour évaluer votre utilisation du site Web, pour produire des rapports sur
            l’activité du site Web à&nbsp;l’intention des exploitants du site Web et pour fournir d’autres services
            relatifs à&nbsp;l’activité du site Web et à&nbsp;l’utilisation d’Internet. Google peut également transmettre
            ces renseignements à&nbsp;des tiers lorsque la loi l’y oblige ou lorsque lesdits tiers traitent les
            renseignements pour son compte. Google n’associera pas votre adresse IP à&nbsp;toute autre donnée qu’elle
            détient. Vous pouvez refuser l’utilisation de témoins en configurant adéquatement votre navigateur;
            toutefois, comme nous l’avons mentionné ci-dessus, si vous le faites, il se peut que vous ne puissiez pas
            utiliser toutes les fonctionnalités d’un site Web de Roche. En utilisant un site Web de Roche, vous
            consentez au traitement par Google des données vous concernant de la manière et aux fins mentionnées
            ci-dessus.
          </p>
          <h3>Liens vers d’autres sites</h3>
          <p>
            Nos sites contiennent des liens vers un certain nombre de sites Web susceptibles d’offrir des renseignements
            utiles à&nbsp;nos visiteurs. Le présent énoncé de confidentialité ne s’applique pas à&nbsp;ces sites, et
            nous vous recommandons de communiquer directement avec eux pour obtenir des renseignements sur leur
            politique en matière de confidentialité.
          </p>
          <h3>Énoncé de confidentialité à&nbsp;l’intention des enfants</h3>
          <p>
            Nos sites Web s’adressent à&nbsp;un auditoire adulte. Nous ne recueillons pas sciemment de renseignements
            personnels de quiconque âgé de moins de 13 ans sans le consentement préalable et vérifiable de son
            représentant légal. Ce représentant légal a le droit, sur demande, de consulter les renseignements fournis
            par cet enfant ou d’exiger qu’ils soient supprimés.
          </p>
          <h3>Avis aux utilisateurs de sites Web commerciaux ou professionnels</h3>
          <p>
            Si la relation que vous entretenez avec Roche est d’ordre commercial ou professionnel, nous pouvons utiliser
            les renseignements que vous nous fournissez sur nos sites, y compris les sites destinés précisément aux
            utilisateurs commerciaux et professionnels, pour répondre à&nbsp;vos demandes et développer notre relation
            d’affaires avec vous et les entités que vous représentez. Nous pouvons également partager ces renseignements
            avec des tiers qui agissent en notre nom.
          </p>
          <h3>Mises à&nbsp;jour de l’énoncé de confidentialité</h3>
          <p>
            Roche peut réviser, de temps à&nbsp;autre, le présent énoncé de confidentialité en ligne. Toute modification
            au présent énoncé de confidentialité sera communiquée le plus rapidement possible sur cette page.
            L’utilisation continue de nos sites après la réception d’un avis de changement de notre énoncé de
            confidentialité indique que vous consentez à&nbsp;utiliser les renseignements nouvellement émis conformément
            à&nbsp;l’énoncé de confidentialité de Roche modifié. La date d’entrée en vigueur du présent énoncé de
            confidentialité est le{' '}
            <b>
              1<sup>er</sup> juillet 2013.
            </b>
          </p>
          <h3>Comment communiquer avec Roche</h3>
          <p>
            Si vous avez des questions ou si vous désirez que Roche modifie ou supprime votre profil, veuillez
            communiquer avec nous en nous écrivant (par courrier postal), à&nbsp;l’adresse suivante :
          </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    Hoffmann-La Roche Limited/Limitée (Pharma)
                    <br />
                    Responsable de la protection
                    <br />
                    des renseignements personnels
                    <br />
                    7070 Mississauga Road
                    <br />
                    Mississauga (Ontario)
                    <br />
                    L5N 5M8
                  </p>
                </td>
                <td>
                  <p>
                    Roche Diagnostics
                    <br />
                    Division de Hoffmann-La Roche Limited/Limitée
                    <br />
                    Responsable de la protection
                    <br />
                    des renseignements personnels
                    <br />
                    201, boul. Armand-Frappier
                    <br />
                    Laval (Québec)  H7V 4A2
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </TranslatedBlock>
      </Layout>
    );
  }
}
